let prefixer: string | undefined;

const hostname:string = window.location.hostname;
const port:string = window.location.port;

switch (hostname) {
case process.env.REACT_APP_STAGING_HOST:
  prefixer = process.env.REACT_APP_STAGING_API_URL;
  break;
case process.env.REACT_APP_DEV_HOST:
  prefixer = process.env.REACT_APP_DEV_API_URL;
  break;
case process.env.REACT_APP_PROD_HOST:
  prefixer = process.env.REACT_APP_PROD_API_URL;
  break;
default:
  if (port === '3000') {
    prefixer = process.env.REACT_APP_LOCAL_API_URL;
  } else {
    prefixer = process.env.REACT_APP_DEV_API_URL;
  }
}

export const AuthUrls: Record<string, string> = {
  PASSWORD_LOGIN: `${ prefixer }/auth/login`,
  RESET_PASSWORD_INIT: `${ prefixer }/auth/forgot-password`,
  RESET_PASSWORD: `${ prefixer }/auth/reset-password`,
  CREATE_PASSWORD: `${ prefixer }/auth/create-password`,
  REFRESH_TOKEN: `${ prefixer }/auth/refresh-token`,
  IMPERSONATE: `${ prefixer }/auth/impersonate`,
};

export const UserUrls: Record<string, string> = {
  GET_CONNECTED_INFO: `${ prefixer }/user/me`,
  GET_ACCOUNT_INFO: `${ prefixer }/user/infos`,
  GET_ACCOUNT_SF_OWNER_INFO: `${ prefixer }/user/me/sf-owner`,
  GET_ACCOUNT_SF_NOTIFICATIONS: `${ prefixer }/user/me/sf-notifications`,
};

export const CalculatriceCEEUrls: Record<string, string> = {
  LISTE_FOST: `${ prefixer }/calculatriceCEE/listeFost`,
  CRITERES_ELIGIBILITE: `${ prefixer }/calculatriceCEE/criteresEligibilite`,
  CHAMPS_REQUIS: `${ prefixer }/calculatriceCEE/champsRequis`,
  VOLUME_ET_PRIME_CEE: `${ prefixer }/calculatriceCEE/volumeEtPrimeCEE`,
  SIMULATION: `${ prefixer }/calculatriceCEE/simulation`,
};

export const HubspotUrls: Record<string, string> = {
  SEND_EMAIL: `${ prefixer }/hubspot/sendEmail`,
}

export const ExternalUrls = {
  GET_NEWS_RSS: 'https://www.hellio.com/actualites/rss.xml',
};

export const LOCATION_URL= {
  SEARCH: (searchValue: string)=>`https://api-adresse.data.gouv.fr/search/?q=${searchValue}&postcode=${searchValue}&type=municipality`,
}
